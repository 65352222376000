import React from 'react'
import './Page4.css'

import HoverImage from '../../../components/HoverImage'

export default class Page4 extends React.Component {
    constructor() {
        super()
    }

    render() { return (
        <div className='Page fadeIn' id='Page4' style={{ backgroundImage: `url(${this.props.assets.achtergrond})` }}>
            <video id='schaduw' autoPlay muted loop>
                <source src={this.props.assets.vliegtuig} type='video/webm'/>
            </video>
            <video id='vliegtuig' autoPlay muted loop>
                <source src={this.props.assets.vliegtuig} type='video/webm'/>
            </video>
            <HoverImage
            id='paard'
            a={this.props.assets.paardA}
            b={this.props.assets.paardB}
            onClick={() => this.props.history.push('/1/5/paard')}
            />
            <HoverImage
            id='auto'
            a={this.props.assets.autoA}
            b={this.props.assets.autoB}
            onClick={() => this.props.history.push('/1/5/auto')}
            />
        </div>
    )}
}