import React from 'react'
import './HoverImage.css'

export default class HoverImage extends React.Component {
    render() { return (
        <div className={`HoverImage ${this.props.className}`} id={this.props.id} onClick={this.props.onClick}>
            <img className='image a' src={this.props.a} />
            <img className='image b' src={this.props.b} />
        </div>
    )}
}