import React from 'react'
import './Arena.css'

import ActionImage from '../../../components/ActionImage'

export default class Arena extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            playersTurn: true,
            jalien: {
                lives: 3,
                health: 100,
                attack: null
            },
            koe: {
                lives: 1,
                health: 100,
                attack: null
            }
        }
        this.musicRef = React.createRef()
    }

    attackCycle = async attackName => {
        this.setState({ playersTurn: false })
        try {
            await this.attack(attackName, 'jalien', 'koe')
            await this.attack('vuur', 'koe', 'jalien')
        }
        catch (target) {
            if (target === 'jalien') {
                const jalien = this.state.jalien
                jalien.lives = 2
                setTimeout(() => this.setState({ jalien: jalien }), 1500)
            }
            setTimeout(() => this.props.history.push('/wip'), 5000)
            return
        }
        this.setState({ playersTurn: true })
    }

    attack = (attackName, attackerName, targetName) => {
        return new Promise((resolve, reject) => {
            const attack = attacks[attackerName][attackName]
            const attacker = this.state[attackerName]
            attacker.attack = {
                name: attackName,
                animation: this.props.assets[attackName],
                audio: this.props.assets[`${attackName}Audio`],
                replaces: attack.replaces
            }
            this.setState({ [attackerName]: attacker })

            setTimeout(() => {
                const target = this.state[targetName]
                target.health = target.health - attack.damage
                if (target.health < 0) target.health = 0

                attacker.attack = null
                this.setState({ [attackerName]: attacker, [targetName]: target })

                setTimeout(() => {
                    if (target.health === 0)    return reject(targetName)
                    else                        return resolve()
                }, 2000)
            }, attack.duration)
        })
    }

    loopMusic = () => {
        this.musicRef.current.currentTime = 3
        this.musicRef.current.play()
    }

    render() { return (
        <div className='Arena fullSize' style={{ backgroundImage: `url(${this.props.assets.achtergrond})` }}>
            <audio ref={this.musicRef} src={this.props.assets.music} autoPlay onEnded={this.loopMusic}/>

            <Character
            id='jalien'
            idle={this.props.assets.jalien}
            attack={this.state.jalien.attack}
            health={this.state.jalien.health}
            death={this.props.assets.jalienSterven}
            />

            <Character
            id='koe'
            idle={this.props.assets.koe}
            attack={this.state.koe.attack}
            health={this.state.koe.health}
            death={this.props.assets.koeSterven}
            />

            <HealthBar health={this.state.jalien.health} assets={this.props.assets} id='healthBar-jalien'/>
            <HealthBar health={this.state.koe.health} assets={this.props.assets} id='healthBar-koe'/>

            <img className='hart' id='one' src={this.props.assets.hart} />
            <img className='hart' id='two' src={this.props.assets.hart} />
            <img className='hart' id='three' src={this.state.jalien.lives === 3 ? this.props.assets.hart : this.props.assets.hartVerliezen} />

            <div id='knoppen' style={{ backgroundImage: `url(${this.props.assets.achtergrondKnoppen})`} }>
                <ActionImage
                id='vuist-knop'
                a={this.props.assets.vuistA}
                b={this.props.assets.vuistB}
                duration={4000}
                clickable={this.state.playersTurn}
                onClick={() => this.attackCycle('vuist')}
                />
                <ActionImage
                id='bom-knop'
                a={this.props.assets.bomA}
                b={this.props.assets.bomB}
                duration={5000}
                clickable={this.state.playersTurn}
                onClick={() => this.attackCycle('bom')}
                />
                <ActionImage
                id='tranen-knop'
                a={this.props.assets.tranenA}
                b={this.props.assets.tranenB}
                duration={6000}
                clickable={this.state.playersTurn}
                onClick={() => this.attackCycle('tranen')}
                />
            </div>

            <img className='fullSize' id='koe-intro' src={this.props.assets.koeIntro}/>
        </div>
    )}
}


class Character extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            animation: this.props.idle,
            showCharacter: true
        }
        this.audioRef = React.createRef()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.health !== this.props.health) {
            if (this.props.health === 0) {
                setTimeout(() => this.setState({ animation: this.props.death }), 2000)
                
                // setTimeout(() => this.props.history.push('/wip'), 6000)
            }
        }
        
        if (prevProps.attack?.replaces && !this.props.attack) {
            this.setState({ showCharacter: true })
        }
    }

    handleLoad = () => {
        if (this.props.attack.replaces) {
            setTimeout(() => this.setState({ showCharacter: false }), 0)
        }
        this.audioRef.current.play()
    }

    render() { return (
        <React.Fragment>
            <img id={this.props.id} src={this.state.animation} style={{ display: this.state.showCharacter ? '' : 'none' }}/>
            <img className={'attack'} id={this.props.attack?.name} src={this.props.attack?.animation ? this.props.attack.animation : ''} onLoad={this.handleLoad}/>
            <audio ref={this.audioRef} src={this.props.attack?.audio} />
        </React.Fragment>
    )}
}


class HealthBar extends React.Component {
    constructor() {
        super()
        this.state = {
            width: 34,
            steps: 1
        }
    }

    componentDidMount() {
        const width = Math.round(this.props.health / 100 * 32 + 2)
        this.setState({ width: width })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.health !== this.props.health) {
            const width = Math.round(this.props.health / 100 * 32 + 2)
            const steps = Math.abs(prevState.width - width)
            this.setState({ steps: steps })
            setTimeout(() => this.setState({ width: width }), 100)
        }
    }

    render() { return (
        <div className=' healthBar' id={this.props.id} style={{ backgroundImage: `url(${this.props.assets.healthBarAchter})` }}>
            <div className='healthBarVoor fullSize' 
            style={{
                backgroundImage: `url(${this.props.assets.healthBarVoor})`,
                width: `${this.state.width}px`,
                transitionTimingFunction: `steps(${this.state.steps})`
            }}/>
        </div>
    )}
}

const attacks = {
    jalien: {
        vuist: {
            damage: 100 / 3,
            duration: 4000,
            replaces: true
        },
        bom: {
            damage: 100 / 3,
            duration: 5000
        },
        tranen: {
            damage: 50,
            duration: 6000
        }
    },
    koe: {
        vuur: {
            damage: 50,
            duration: 6000,
            replaces: true
        }
    }
}
