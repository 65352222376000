import React from 'react'
import './Page2.css'
import ActionImage from '../../../components/ActionImage'
import HoverImage from '../../../components/HoverImage'

export default class Page2 extends React.Component {
    constructor() {
        super()
        this.state = {
            vogel: false
        }
        this.vogelAudioRef = React.createRef()
        this.vliegenAudioRef = React.createRef()
    }

    componentDidMount() {
        this.startVideo()
    }

    treeClicked = () => {
        if (!this.state.vogel) {
            this.vogelAudioRef.current.play()
            this.setState({ vogel: true })
        }
    }

    startVideo = () => {
        this.refs.videoRef.play()
        this.vliegenAudioRef.current.currentTime = 0
        setTimeout(() => this.vliegenAudioRef.current.play(), 300)
    }

    render() { return (
        <div className='Page fadeIn' id='Page2' style={{ backgroundImage: `url(${this.props.assets.achtergrond})` }}>
            <audio ref={this.vogelAudioRef} src={this.props.assets.vogelAudio}/>
            <audio ref={this.vliegenAudioRef} src={this.props.assets.vliegenAudio}/>


            <HoverImage
            id='wolk'
            a={this.props.assets.wolkA}
            b={this.props.assets.wolkB}
            onClick={() => {
                this.props.history.push('/1/3')
            }}
            />
            <video className='fullSize' id='video' ref='videoRef' autoPlay muted onEnded={this.startVideo}>
                <source src={this.props.assets.vliegen} type="video/webm" />
            </video>
            {this.state.vogel ? <img className='fullSize' id='vogel' src={this.props.assets.vogel} /> : ''}
            <img className={this.state.vogel ? '' : 'pointer'} id='boom' onClick={this.treeClicked} src={this.props.assets.boom} />
            <ActionImage
            id='koe'
            a={this.props.assets.koeA}
            b={this.props.assets.koeB}
            sound={this.props.assets.moo}
            duration={3000}
            />
        </div>
    )}
}
