import React from 'react'

export default class Page11 extends React.Component {
    constructor() {
        super()
        this.state = {
            video: null,
            nextUrl: ''
        }
    }

    componentDidMount() {
        const path = window.location.pathname
        if (path === '/1/9/kraan') {
            this.setState({ video: this.props.assets.kraan, nextUrl: '/' })
        }
        else {
            this.setState({ video: this.props.assets.brood, nextUrl: '/1/10' })
        }
    }

    render() { return (
        <div className='Page' id='Page10'>
            <video className='fullSize' autoPlay onEnded={() => this.props.history.push(`${this.state.nextUrl}`)}>
                {this.state.video ? <source src={this.state.video} type='video/mp4' /> : ''}
            </video>
        </div>
    )}
}