import React from 'react'
import './ActionImage.css'

export default class ActionImage extends React.Component {
    constructor() {
        super()
        this.state = {
            active: false
        }
        this.audioRef = React.createRef()
    }

    componentDidMount() {
        if (this.props.soundOn && !this.props.duration) {
            this.audioRef.current.loop = true
        }
    }

    handleClick = () => {
        if (!this.isClickable()) return
        if (this.props.duration) {
            this.setState({ active: true })
            setTimeout(() => this.setState({ active: false }), this.props.duration)

            if (this.props.sound) this.audioRef.current.play()
        }
        else {
            if (this.props.soundOn) {
                if (this.state.active) {
                    this.audioRef.current.pause()
                    this.audioRef.current.currentTime = 0
                }
                else {
                    this.audioRef.current.play()
                }
            }
            this.setState({ active: !this.state.active })
        }
        if (this.props.onClick) this.props.onClick()
    }

    isClickable = () => {
        if (this.props.clickable === false) return false
        if (this.props.duration) return !this.state.active
        else return true
    }

    render() { return (
        <div className={`ActionImage ${this.state.active ? 'active' : ''} ${this.isClickable() ? 'clickable' : ''}`} id={this.props.id} onClick={this.handleClick}>
            <audio ref={this.audioRef} src={this.props.soundOn || this.props.sound} />
            <img className='image' src={this.state.active ? this.props.b : this.props.a} />
        </div>
    )}
}