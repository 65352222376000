import React from 'react'

export default class Page7 extends React.Component {
    constructor() {
        super()
        this.state = {
            video: null
        }
    }

    componentDidMount() {
        const path = window.location.pathname
        if (path === '/1/7/paard') {
            this.setState({ video: this.props.assets.paard })
        }
        else {
            this.setState({ video: this.props.assets.auto })
        }
    }

    render() { return (
        <div className='Page' id='Page7'>
            <video className='fullSize' autoPlay onEnded={() => this.props.history.push('/1/8')}>
                {this.state.video ? <source src={this.state.video} type='video/mp4' /> : ''}
            </video>
        </div>
    )}
}