import React from 'react'

import HoverImage from './HoverImage'

const background = require('./wip.jpg')
const restartA = require('./restart 1.png')
const restartB = require('./restart 2.png')

export default class WIP extends React.Component {
    render() { return (
        <div className='Page fadeIn' style={{ backgroundImage: `url(${background})` }}>
            <HoverImage
            id='returnButton'
            a={restartA}
            b={restartB}
            onClick={() => this.props.history.push('/1/1')}
            />
        </div>
    )}
}