import React from 'react'

export default class Page11 extends React.Component {
    constructor() {
        super()
        this.state = {
            video: null,
            nextUrl: ''
        }
    }

    componentDidMount() {
        const path = window.location.pathname
        if (path === '/1/11/computer') {
            this.setState({ video: this.props.assets.computer, nextUrl: '/2/overworld' })
        }
        else {
            this.setState({ video: this.props.assets.boot, nextUrl: '/3/1' })
        }
    }

    render() { return (
        <div className='Page' id='Page10'>
            <video className='fullSize' autoPlay onEnded={() => this.props.history.push(`${this.state.nextUrl}`)}>
                {this.state.video ? <source src={this.state.video} type='video/mp4' /> : ''}
            </video>
        </div>
    )}
}