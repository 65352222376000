import React from 'react'
import './Page8.css'
import HoverImage from '../../../components/HoverImage'
import ActionImage from '../../../components/ActionImage'

export default class Page8 extends React.Component {
    // componentDidMount() {
    //     this.props.assets.kraanAudio.play()
    //     this.props.assets.kraanAudio.loop = true

    //     this.props.assets.klokAudio.play()
    //     this.props.assets.klokAudio.loop = true
    // }

    render() { return (
        <div className='Page fadeIn' id='Page8' style={{ backgroundImage: `url(${this.props.assets.achtergrond})` }}>
            <audio src={this.props.assets.kraanAudio} loop autoPlay />
            <audio src={this.props.assets.klokAudio} loop autoPlay />
            <video id='vis' src={this.props.assets.vis} autoPlay muted loop />
            <img id='klok' src={this.props.assets.klok} />
            <HoverImage
            id='brood'
            a={this.props.assets.broodA}
            b={this.props.assets.broodB}
            onClick={() => this.props.history.push('/1/9/brood')}
            />
            <ActionImage
            id='telefoon'
            a={this.props.assets.telefoonA}
            b={this.props.assets.telefoonB}
            duration={1800}
            sound={this.props.assets.telefoonAudio}
            />
            <ActionImage
            id='lamp'
            a={this.props.assets.lampA}
            b={this.props.assets.lampB}
            soundOn={this.props.assets.lampAudio}
            />
            <HoverImage
            id='kraan'
            a={this.props.assets.kraanA}
            b={this.props.assets.kraanB}
            onClick={() => this.props.history.push('/1/9/kraan')}
            />
            <img id='kraan' style={{ pointerEvents: 'none' }} src={this.props.assets.druppel} />
            <img id='vaat' src={this.props.assets.vaat} />
        </div>
    )}
}