const assets = {
    1: {
        video: 'video.mp4',
        intro: 'intro.png',
        animatie: 'animatie.gif',
        startA: 'start 1.png',
        startB: 'start 2.png',
        schuifwolken: 'schuifwolken.jpg',
        jalien: 'jalien.png',
        jalienLijn: 'jalien lijn.png',
        stijn: 'stijn.png'
    },
    2: {
        boom: 'boom.png',
        vogel: 'vogel.gif',
        vogelAudio: 'vogel.mp3',
        koeA: 'koe 1.png',
        koeB: 'koe 2.png',
        moo: 'moo.mp3',
        vliegen: 'vliegen.webm',
        vliegenAudio: 'vliegen.mp3',
        wolkA: 'wolk 1.png',
        wolkB: 'wolk 2.png',
        achtergrond: 'achtergrond.jpg'
    },
    3: {
        video: 'vliegen.mp4'
    },
    4: {
        paardA: 'paard 1.png',
        paardB: 'paard 2.png',
        autoA: 'auto 1.png',
        autoB: 'auto 2.png',
        vliegtuig: 'vliegtuig.webm',
        achtergrond: 'achtergrond.jpg'
    },
    5: {
        paard: 'paard.mp4',
        auto: 'auto.mp4'
    },
    6: {
        achtergrond: 'achtergrond.jpg',
        deurA: 'deur 1.png',
        deurB: 'deur 2.png',
        auto: 'auto.webm',
        paard: 'paard.webm',
        rook: 'rook.gif'
    },
    7: {
        auto: 'auto.mp4',
        paard: 'paard.mp4'
    },
    8: {
        vis: 'vis.webm',
        klok: 'klok.gif',
        klokAudio: 'klok.mp3',
        broodA: 'brood 1.png',
        broodB: 'brood 2.png',
        lampA: 'lamp 1.png',
        lampB: 'lamp 2.png',
        lampAudio: 'lamp.mp3',
        telefoonA: 'telefoon 1.png',
        telefoonB: 'telefoon 2.gif',
        telefoonAudio: 'telefoon.mp3',
        vaat: 'vaat.png',
        kraanA: 'kraan 1.png',
        kraanB: 'kraan 2.png',
        druppel: 'druppel.gif',
        kraanAudio: 'kraan.mp3',
        achtergrond: 'achtergrond.jpg'
    },
    9: {
        kraan: 'kraan.mp4',
        brood: 'brood.mp4'
    },
    10: {
        achtergrond: 'achtergrond.jpg',
        bordBovenA: 'bord boven 1.png',
        bordBovenB: 'bord boven 2.png',
        bordOnderA: 'bord onder 1.png',
        bordOnderB: 'bord onder 2.png',
        jalien: 'jalien.mp4',
        vogels: 'vogels.mp3',
        lieveheersbeestjeA: 'lieveheersbeestje 1.png',
        lieveheersbeestjeB: 'lieveheersbeestje 2.gif',
        paal: 'paal.png',
        rook: 'rook.gif'
    },
    11: {
        computer: 'computer.mp4',
        boot: 'boot.mp4'
    }
}

export default assets