import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

import './Chapter2.css'

import Overworld from './pages/Overworld'
import Arena from './pages/Arena'

import chapterLoader from '../../components/ChapterLoader'

import assets from './assets'
const laadscherm = require('./assets/laadscherm.gif')

class Chapter2 extends React.Component {
    constructor() {
        super()
        this.state = {
            scaleX: 1,
            scaleY: 1
        }
        this.ref = React.createRef()
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
    }

    handleResize = () => {
        const parent = this.ref.current?.parentNode

        if (!parent) return
        
        this.setState({
            scaleX: parent.clientWidth / 218,
            scaleY: parent.clientHeight / 123
        })
    }

    render() { return (
        <div className='Chapter2' ref={this.ref} style={{ transform: `scale(${this.state.scaleX}, ${this.state.scaleY})` }}>
                <Switch>
                    <Route path='/2/arena' render={ props => <Arena {...props} assets={this.props.assets.arena} /> } />
                    <Route path='/2' render={ props => <Overworld {...props} assets={this.props.assets.overworld} /> } />
                </Switch>
        </div>
    )}
}

export default withRouter(chapterLoader(Chapter2, assets, '2', laadscherm))