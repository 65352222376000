import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import assets from './assets'

import Page1 from './pages/Page1'
import Page2 from './pages/Page2'
import Page3 from './pages/Page3'
import Page4 from './pages/Page4'
import Page5 from './pages/Page5'
import Page6 from './pages/Page6'
import Page7 from './pages/Page7'
import Page8 from './pages/Page8'
import Page9 from './pages/Page9'
import Page10 from './pages/Page10'
import Page11 from './pages/Page11'

import chapterLoader from '../../components/ChapterLoader'

const laadscherm = require('./assets/laadscherm.gif')

class Chapter1 extends React.Component {
    render() { return (
        <Switch>
            <Route path='/1/2' render={ props => <Page2 {...props} assets={this.props.assets[2]} /> } />
            <Route path='/1/3' render={ props => <Page3 {...props} assets={this.props.assets[3]} /> } />
            <Route path='/1/4' render={ props => <Page4 {...props} assets={this.props.assets[4]} /> } />
            <Route path='/1/5' render={ props => <Page5 {...props} assets={this.props.assets[5]} /> } />
            <Route path='/1/6' render={ props => <Page6 {...props} assets={this.props.assets[6]} /> } />
            <Route path='/1/7' render={ props => <Page7 {...props} assets={this.props.assets[7]} /> } />
            <Route path='/1/8' render={ props => <Page8 {...props} assets={this.props.assets[8]} /> } />
            <Route path='/1/9' render={ props => <Page9 {...props} assets={this.props.assets[9]} /> } />
            <Route path='/1/10' render={ props => <Page10 {...props} assets={this.props.assets[10]} /> } />
            <Route path='/1/11' render={ props => <Page11 {...props} assets={this.props.assets[11]} /> } />
            <Route path='/' render={ props => <Page1 {...props} assets={this.props.assets[1]} /> } />
        </Switch>
    )}
}

export default withRouter(chapterLoader(Chapter1, assets, '1', laadscherm))