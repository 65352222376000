import React from 'react'

export default class Page3 extends React.Component {
    constructor() {
        super()
    }

    render() { return (
        <video className="Page" id='Page3' autoPlay onEnded={() => this.props.history.push('/1/4')}>
            <source src={this.props.assets.video} type="video/mp4" />
        </video>
    )}
}