import React from 'react'
import './Page10.css'

import HoverImage from '../../../components/HoverImage'
import ActionImage from '../../../components/ActionImage'

export default class Page10 extends React.Component {
    constructor() {
        super()
        this.videoRef = React.createRef()
    }

    loopVideo = () => {
        this.videoRef.current.currentTime = 6.833
        this.videoRef.current.play()
    }

    render() { return (
        <div className='Page' id='Page9'>
            <audio src={this.props.assets.vogels} autoPlay loop />

            <video className='fullSize' ref={this.videoRef} autoPlay muted onEnded={this.loopVideo}>
                <source src={this.props.assets.jalien} type="video/mp4" />
            </video>

            <img id='rook' src={this.props.assets.rook}/>
            <img id='paal' src={this.props.assets.paal}/>

            <HoverImage
            a={this.props.assets.bordBovenA}
            b={this.props.assets.bordBovenB}
            id='bordBoven'
            onClick={() => this.props.history.push('/1/11/computer')}
            />
            <HoverImage
            a={this.props.assets.bordOnderA}
            b={this.props.assets.bordOnderB}
            id='bordOnder'
            onClick={() => this.props.history.push('/1/11/boot')}
            />
            <ActionImage
            a={this.props.assets.lieveheersbeestjeA}
            b={this.props.assets.lieveheersbeestjeB}
            duration={1800}
            id='lieveheersbeestje'
            />
        </div>
    )}
}