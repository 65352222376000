import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './Window.css'

import Chapter1 from '../chapters/1/Chapter1'
import Chapter2 from '../chapters/2/Chapter2'
import Chapter3 from '../chapters/3/Chapter3'
import WIP from './wip'

export default class Window extends React.Component {
    isChromeOrFirefox() {
        return true

        const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
        const isFirefox = typeof InstallTrigger !== 'undefined'

        return isChrome || isFirefox
    }

    render() { return (
        <Router>
            <div className='Window'>
            {this.isChromeOrFirefox() ? 
                <Switch>
                    <Route path='/2' component={Chapter2} />
                    <Route path='/3' component={Chapter3} />
                    <Route path='/wip' component={WIP} />
                    <Route path='/' component={Chapter1} />
                </Switch> : ''
            }
            </div>
        </Router>
    )}
}