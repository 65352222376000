const assets = {
    overworld: {
        pijltjes: 'pijltjes.gif',
        pijltjesUitBeeld: 'pijltjes uit beeld.gif',
        hart: 'hart.gif',
        parralaxAchter: 'parralax achter.gif',
        parralaxMidden: 'parralax midden.png',
        parralaxVoor: 'parralax voor.png',
        jalienStilstaan: 'jalien stilstaan.png',
        jalienLopen: 'jalien-lopen.gif',
        koe: 'koe.gif',
        reeks: 'reeks.png',
        transitie: 'transitie.gif',
        transitieAudio: 'transitie.mp3',
        music: 'overworld.mp3'
    },
    arena: {
        achtergrond: 'achtergrond.gif',
        achtergrondKnoppen: 'achtergrond knoppen.png',
        koe: 'koe.gif',
        jalien: 'vechthouding.gif',
        bomA: 'bom knop.png',
        bomB: 'bom knop ingedrukt.png',
        tranenA: 'tranen knop.png',
        tranenB: 'tranen knop ingedrukt.png',
        vuistA: 'vuist knop.png',
        vuistB: 'vuist knop ingedrukt.png',
        bom: 'bom.gif',
        tranen: 'tranen.gif',
        vuist: 'vuist.gif',
        healthBarAchter: 'health bar achter.png',
        healthBarVoor: 'health bar voor.png',
        vuur: 'vuur.gif',
        jalienSterven: 'jalien sterven.gif',
        koeSterven: 'koe sterven.gif',
        koeIntro: 'koe-intro.gif',
        hart: 'hart.gif',
        hartVerliezen: 'hart verliezen.gif',
        bomAudio: 'bom.mp3',
        tranenAudio: 'tranen.mp3',
        vuistAudio: 'vuist.mp3',
        vuurAudio: 'vuur.mp3',
        music: 'arena.mp3'
    }
}

export default assets