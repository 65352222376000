import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import assets from './assets'

import Page1 from './pages/Page1'

import chapterLoader from '../../components/ChapterLoader'
import laadscherm from './assets/laadscherm.gif'

class Chapter3 extends React.Component {
    render() { return (
        <Switch>
            <Route path='/3/1' render={ props => <Page1 {...props} assets={this.props.assets[1]}/> } />
        </Switch>
    )}
}

export default withRouter(chapterLoader(Chapter3, assets, '3', laadscherm))