import React from 'react'

export default function chapterLoader(Chapter, assets, chapter, laadscherm) {
    return class ChapterLoader extends React.Component {
        constructor() {
            super()
            this.state = {
                loading: true,
                assetsLoaded: 0,
                assetsTotal: 0,
                assets: {},
                timeOver: false
            }
        }
    
        componentDidMount() {
            this.setState({ assetsTotal: this.getAssetsTotal() })
            this.preloadAssets()
        }
    
        preloadAssets = () => {
            for (let page in assets) {
                for (let asset in assets[page]) {
                    if (!this.state.assets[page]) this.state.assets[page] = {}
                    try {
                        import(`../chapters/${chapter}/assets/${page}/${assets[page][asset]}`).then(module => {
                            const path = module.default
        
                            if (path.endsWith('.mp3')) {
                                const audio = new Audio()
                                audio.src = path
                                audio.oncanplaythrough = this.handleAssetLoaded
        
                                this.state.assets[page][asset] = path
                            }
                            else if (path.endsWith('.mp4') || path.endsWith('.webm')) {
                                // const video = document.createElement('video')
                                // video.src = path
                                // video.load()
                                // video.oncanplaythrough = this.handleAssetLoaded

                                this.state.assets[page][asset] = path
                                this.handleAssetLoaded()
                            }
                            else {
                                const image = new Image()
                                image.src = path
                                
                                image.onload = this.handleAssetLoaded
                                if (image.complete) this.handleAssetLoaded()
        
                                this.state.assets[page][asset] = path
                            }
                        })
                    }
                    catch (err) {
                        console.log(err)
                        this.handleAssetLoaded()
                    }                
                }
            }
        }
    
        getAssetsTotal = () => {
            let total = 0
    
            for (let page in assets) {
                total += Object.keys(assets[page]).length
            }
    
            return total
        }
    
        handleAssetLoaded = () => {
            const assetsLoaded = this.state.assetsLoaded + 1
            let loading = true
    
            if (assetsLoaded >= this.state.assetsTotal) loading = false
    
            this.setState({
                assetsLoaded: assetsLoaded,
                loading: loading
            })
        }
    
        render() { return (
            <React.Fragment>
                {this.state.loading && !this.state.timeOver ? ''
                :
                <Chapter assets={this.state.assets} />
                }
                <div id='laadscherm' style={{ opacity: this.state.loading ? 1 : 0 }}>
                    <img id='laadscherm-image' src={laadscherm}/>
                </div>
            </React.Fragment>
        )}
    }
}