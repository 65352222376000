import React from 'react'
import './Page6.css'
import HoverImage from '../../../components/HoverImage'

export default class Page6 extends React.Component {
    constructor() {
        super()
        this.state = {
            video: null,
            videoId: ''
        }
    }

    componentDidMount() {
        const path = window.location.pathname
        if (path === '/1/6/paard') {
            this.setState({ video: this.props.assets.paard, videoId: 'paard' })
        }
        else {
            this.setState({ video: this.props.assets.auto, videoId: 'auto' })
        }
    }

    render() { return (
        <div className='Page fadeIn' id='Page6' style={{ backgroundImage: `url(${this.props.assets.achtergrond})` }}>
            <img id='rook' src={this.props.assets.rook}/>
            <HoverImage
            id='deur'
            a={this.props.assets.deurA}
            b={this.props.assets.deurB}
            onClick={() => this.props.history.push(`/1/7/${this.state.videoId}`)}
            />
    	    {this.state.video ? <video id={this.state.videoId} autoPlay muted loop>
                <source src={this.state.video} type='video/webm' />
            </video> : ''}
        </div>
    )}
}