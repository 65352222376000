import React from 'react'
import './Overworld.css'

export default class Overworld extends React.Component {
    constructor() {
        super()
        this.state = {
            x: 0,
            jalienX: 0,
            worldX: 0,
            frame: 0,
            direction: 'r',
            showArrows: true,
            introOver: false
        }
        this.keys = []
        this.musicRef = React.createRef()
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ introOver: true })
            document.addEventListener('keydown', this.handleKeydown)
            document.addEventListener('keyup', this.handleKeyup)
            this.musicRef.current.play()
    
            this.moveInterval = setInterval(() => {
                this.move()
            }, 150)
        }, 11800)
    }

    componentWillUnmount() {
        clearInterval(this.moveInterval)
    }

    handleKeydown = (e) => {
        if (!this.keys.includes(e.keyCode))
            this.keys.push(e.keyCode)
    }

    handleKeyup = (e) => {
        if (this.keys.includes(e.keyCode))
            this.keys = this.keys.filter(key => key !== e.keyCode)
    }

    move = () => {
        console.log('move')

        let x = this.state.x
        let frame = this.state.frame
        let direction = this.state.direction
        let showArrows = this.state.showArrows

        const leftArrow = this.keys.includes(37) && x > -16
        const rightArrow = this.keys.includes(39)

        if (leftArrow ? !rightArrow : rightArrow) {
            if (leftArrow) {
                x -= 2
                direction = 'l'
            }
            if (rightArrow) {
                x += 2
                direction = 'r'
            }
            
            if (frame < 7) frame++
            else if (frame > 8) frame = 1 + (10 - frame)
            else frame = 4

            showArrows = false
        }
        else {
            if (frame > 7 && frame <= 10) frame++
            if (frame > 3 && frame <= 7) {
                this.state.direction === 'l' ? x -= 2 : x += 2
                frame++
            }
            if (frame > 0 && frame < 4) {
                frame--
            }
            if (frame > 10) frame = 0
        }

        if (x > 255) {
            this.props.history.push('/2/arena')
        }

        if (x < -16) x = -16

        this.setState({
            x: x,
            jalienX: this.getJalienX(x),
            worldX: this.getWorldX(x),
            frame: frame,
            direction: direction,
            showArrows: showArrows
        })
    }

    getJalienX = x => {
        let jalienX

        // if (x > 140) jalienX = x - 70
        if (x > 70) jalienX = 70
        else jalienX = x

        return jalienX
    }

    getWorldX = x => {
        let worldX

        // if (x > 140) worldX = 70
        if (x > 70) worldX = x - 70

        return worldX
    }

    loopMusic = () => {
        this.musicRef.current.currentTime = 36
        this.musicRef.current.play()
    }

    render() { return (
        <div className='Overworld fullSize'>
            <audio src={this.props.assets.transitieAudio} autoPlay/>
            <audio ref={this.musicRef} src={this.props.assets.music} onEnded={this.loopMusic}/>

            <div className='fullSize' id='achter' style={{ backgroundImage: `url(${this.props.assets.parralaxAchter })`, backgroundPositionX: `-${Math.floor(this.state.worldX / 11)}px` }} />
            <div className='fullSize' id='midden' style={{ backgroundImage: `url(${this.props.assets.parralaxMidden })`, backgroundPositionX: `-${Math.floor(this.state.worldX / 4)}px` }} />
            <div className='fullSize' id='voor' style={{ backgroundImage: `url(${this.props.assets.parralaxVoor })`, backgroundPositionX: `-${this.state.worldX}px` }} />

            <img className='hart' id='one' src={this.props.assets.hart} />
            <img className='hart' id='two' src={this.props.assets.hart} />
            <img className='hart' id='three' src={this.props.assets.hart} />

            {this.state.introOver ? '' : <img id='transitie' src={this.props.assets.transitie}/>}

            <div style={{ display: this.state.introOver ? '' : 'none' }}>
                <div
                id='jalien'
                style={{
                    backgroundImage: `url(${this.props.assets.reeks})`,
                    left: `${this.state.jalienX + 16}px`,
                    backgroundPositionX: `-${this.state.frame * 31}px`,
                    transform: this.state.direction === 'l' ? 'scaleX(-1)' : ''
                }}
                />
                <img id='pijltjes' src={this.state.showArrows ? this.props.assets.pijltjes : this.props.assets.pijltjesUitBeeld} />
            </div>

            <img id='koe' src={this.props.assets.koe} style={{ left: `${300 - this.state.worldX}px` }}/>
        </div>
    )}
}