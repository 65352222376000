import React from 'react'

export default class Page5 extends React.Component {
    constructor() {
        super()
        this.state = {
            video: null,
            nextUrl: ''
        }
    }

    componentDidMount() {
        const path = window.location.pathname
        if (path === '/1/5/paard') {
            this.setState({ video: this.props.assets.paard, nextUrl: '/1/6/paard' })
        }
        else {
            this.setState({ video: this.props.assets.auto, nextUrl: '/1/6/auto' })
        }
    }

    render() { return (
        <div className='Page' id='Page5'>
            <video className='fullSize' autoPlay onEnded={() => this.props.history.push(`${this.state.nextUrl}`)}>
                {this.state.video ? <source src={this.state.video} type='video/mp4' /> : ''}
            </video>
        </div>
    )}
}

