import React from 'react'
import HoverImage from '../../../components/HoverImage'
import './Page1.css'

export default class Page1 extends React.Component {
    constructor() {
        super()
        this.state = {
            animating: false,
            showStart: true
        }
        this.videoRef = React.createRef()
    }

    onStartClick = () => {
        this.setState({ animating: true })
        setTimeout(() => this.setState({ showStart: false }), 600)
        setTimeout(() => this.videoRef.current.play(), 1500)
    }

    render() { return (
        <div className='Page' id='Page1' style={{ backgroundImage: `url(${this.props.assets.schuifwolken})` }}>
            <div className={`${this.state.showStart ? '' : 'hidden'}`}>
                <img className='fullSize' src={this.props.assets.intro} />
                <HoverImage
                className='fullSize'
                id='startButton'
                a={this.props.assets.startA}
                b={this.props.assets.startB}
                onClick={this.onStartClick}
                />
            </div>
            <div className={`${this.state.showStart ? 'hidden' : ''}`}>
                <video ref={this.videoRef} className='fullSize' onEnded={() => this.props.history.push('/1/2')}>
                    <source src={this.props.assets.video} type="video/mp4" />
                </video>
            </div>
            <img className={`fullSize ${this.state.animating ? '' : 'hidden'}`} src={this.props.assets.animatie} />

            <div id='names' style={{ opacity: this.state.showStart ? 1 : 0 }}>
                <HoverImage
                id='jalien'
                className={this.state.showStart ? '' : 'nonClickable'}
                a={this.props.assets.jalien}
                b={this.props.assets.jalienLijn}
                onClick={() => {if (this.state.showStart) window.open('https://www.instagram.com/jalienwinter')}}
                />

                <img id='stijn' src={this.props.assets.stijn}/>
            </div>
        </div>
    )}
}
