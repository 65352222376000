const assets = {
    1: {
        golf1: 'golf 1.png',
        golf2: 'golf 2.png',
        golf3: 'golf 3.png',
        golf4: 'golf 4.png',
        golf5: 'golf 5.png',
        wolken: 'wolken.jpg',
        circus: 'circustent.png',
        huis: 'huis.png',
        briefOpenen: 'brief-openen.gif',
        meeuw: 'meeuw.gif',
        vissen: 'vissen.gif',
        boot: 'boot.webm',
        fles: 'fles.png',
        kaart: 'kaart.webm',
        kaartOpen: 'kaart-open.webm',
        boei: 'boei.png',
        tentakelVoor: 'tentakel-voor.gif',
        tentakelAchter: 'tentakel-achter.gif',
        zeeAudio: 'zee.mp3',
        kaartAudio: 'kaart.mp3'
    }
}

export default assets