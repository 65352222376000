import React from 'react'
import './Page1.css'

export default class Page1 extends React.Component {
    constructor() {
        super()
        this.state = {
            tentakels: false,
            kaart: false,
            kaartIntroOver: false
        }
        this.kaartRef = React.createRef()
        this.kaartAudioRef = React.createRef()
    }

    playKaart = () => {
        this.setState({ kaartIntroOver: true })
        this.kaartRef.current.play()
    }

    handleBoeiClick = () => {
        if (!this.state.tentakels) {
            this.setState({ tentakels: true })
            setTimeout(() => this.setState({ tentakels: false }), 5000)
        }
    }

    handleKaartClick = () => {
        this.setState({ kaart: true })
        this.kaartAudioRef.current.play()
    }

    render() { return (
        <div className='Page fadeIn' id='Page1' style={{ backgroundImage: `url(${this.props.assets.wolken})` }}>
            <audio src={this.props.assets.zeeAudio} autoPlay loop />
            <audio ref={this.kaartAudioRef} src={this.props.assets.kaartAudio} />

            <img className='golf' id='golf5' src={this.props.assets.golf5}/>

            <img id='tentakel-achter' src={this.props.assets.tentakelAchter} style={{ top: this.state.tentakels ? '20%' : '' }}/>
            <img className={`${this.state.tentakels ? '' : 'clickAble'}`} id='boei' src={this.props.assets.boei} style={{ top: this.state.tentakels ? '65%' : '' }} onClick={this.handleBoeiClick}/>
            <img id='tentakel-voor' src={this.state.tentakels ? this.props.assets.tentakelVoor : ''}/>

            <img className='golf' id='golf4' src={this.props.assets.golf4}/>
            <img className='golf' id='golf3' src={this.props.assets.golf3}/>
            <video id='boot' autoPlay muted loop>
                <source src={this.props.assets.boot} type='video/webm'/>
            </video>
            <img id='vissen' src={this.props.assets.vissen} onClick={() => this.props.history.push('/wip')}/>
            <img className='golf' id='golf2' src={this.props.assets.golf2}/>
            <img id='fles' src={this.props.assets.fles} onClick={this.handleKaartClick}/>
            <img className='golf' id='golf1' src={this.props.assets.golf1}/>
            <img id='meeuw' src={this.props.assets.meeuw}/>

            {this.state.kaart ?
            <div>
                <video className='fullSize' id='kaart' autoPlay muted onEnded={this.playKaart}>
                    <source src={this.props.assets.kaartOpen} type='video/webm'/>
                </video>

                <video ref={this.kaartRef} className='fullSize' id='kaart' muted loop style={{ display: this.state.kaartIntroOver ? '' : 'none' }}>
                    <source src={this.props.assets.kaart} type='video/webm'/>
                </video>

                {this.state.kaartIntroOver ? 
                <div>
                    <img id='huis' src={this.props.assets.huis} onClick={() => this.props.history.push('/1/10')}/>
                    <img id='circus' src={this.props.assets.circus} onClick={() => this.props.history.push('/wip')}/>
                </div>
                : ''}
            </div>
            : ''}


            
        </div>
    )}
}